import { callAPI, makeAPICall, makeEncryptedAPICall, APICall } from '../../utils/ApiCall';
import config from '../../utils/config'
import { ToastContainer, toast } from 'react-toastify';
import base64 from 'base-64'
// import Cookies from 'universal-cookie';
import { setAccessToken,getAccessToken } from '../../utils/utilities';
import { json } from 'react-router-dom';
import axios from 'axios';

 
export const PROFILE_DATA = "PROFILE_DATA"
export const INIT_CALL = "INIT_CALL"
export const CONSENT_DATA = "CONSENT_DATA"
export const CONSENT_AUTHORIZED = "CONSENT_AUTHORIZED"
export const CONSENT_HISTORY = "CONSENT_HISTORY"
export const UPDATE_CONSENT = "UPDATE_CONSENT"
export const SAVE_TEMPID = "SAVE_TEMPID"
export const SAVE_AUTH_TOKEN = "SAVE_AUTH_TOKEN"
export const ENCRYPTAPISUCCCESS = "ENCRYPTAPISUCCCESS"
export const DECRYPTAPISUCCESS = "DECRYPTAPISUCCESS"
export const CREATCONSENTSUCCESS = "CREATCONSENTSUCCESS"
export const CALL_BACK_URL ="CALL_BACK_URL"
export const APP_NAME = "APP_NAME"
export const APP_LOGO = "APP_LOGO"
export const GET_CONSENT_TEXT = "GET_CONSENT_TEXT"
export const GET_CONSENTGROUPDATABY_GROUPID = "GET_CONSENTGROUPDATABY_GROUPID"
export const paramsFromState = "paramsFromState"
export const paramsFromStateHistory = "paramsFromStateHistory"
 
 
export const GET_TEMP_DETAILS = "GET_TEMP_DETAILS"
export const GET_PREFERENCE_CENTER_THEME = "GET_PREFERENCE_CENTER_THEME"
// export const UPDATE_CONSENT = "UPDATE_CONSENT"
// export const UPDATE_CONSENT = "UPDATE_CONSENT"
// export const UPDATE_CONSENT = "UPDATE_CONSENT"
 
function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
 
 
let today = new Date();
let start = today.toISOString();
let end = new Date(today.setFullYear(today.getFullYear() + 1)).toISOString();
// const cookies = new Cookies();
// let authorization = base64.encode(`${config.CLIENT_ID}:${config.CLIENT_SECRET_VALUE}`)
let authorization = base64.encode(`${process.env.REACT_APP_CLIENT_ID}:${process.env.REACT_APP_CLIENT_SECRET_VALUE}`)

let encryptedData_Redirect_Portal = process.env.REACT_APP_REDIRECT_URL;

//let encryptedData_Redirect_Portal = `${process.env.REACT_REDIRECT_URL}`;
console.log('Redirect URL:', encryptedData_Redirect_Portal);
//alert(encryptedData_Redirect_Portal);

let encryptedData = "";

export const generateToken = ()=>{
    //alert('NEW--------->')
    return async (dispatch,getState)=>{
 
        // console.log(`Generating authorization`,authorization)
        let headers ={
           //"Cookie":"esctx=AQABAAAAAAD--DLA3VO7QrddgJg7WevrEeCybPbzgALd36F9hDQD1Tgu83UZmpRLYPREmKoEyWme5hXA6bTQsjVGrbZBvVs98u_L6poUdNLXTMSNqYBRqQlaN-_pHRLQ7J_dwWoQz-xwoH7ywKww1rSNedL4uuvz5GwrRHG462yrT6s0yPJk1OmxiRXQwtaL70xPE8ltxYVNggOP0VBAAgyAhnevowkJG_UJavYCqkR-Dl6-96RTKMhayuJMokS8kfWXDVHAs70gAA; fpc=Av41XIZhIfxAm2BiGJr9BzLD0sudAQAAAKPB0doOAAAA; stsservicecookie=estsfd; x-ms-gateway-slice=estsfd; fpc=Av41XIZhIfxAm2BiGJr9BzLD0sudAQAAAAZi39oOAAAA; stsservicecookie=estsfd; x-ms-gateway-slice=estsfd",
            "Authorization": `Basic ${authorization}`,
        }
        let data = {
            "grant_type":"client_credentials",
            // "scope":`${config.CLIENT_ID_AUTH}/.default`
            "scope":`${process.env.REACT_APP_CLIENT_ID}/.default`,
        }
 
        try{
            let resp = await makeAPICall(config.TOKEN, "POST", data, headers)
            //console.log(`token resp-----------------> ${JSON.stringify(resp)}`);
            let access_token = resp.data.access_token;
            //alert(access_token)
            //console.log(`token resp CHECKING-----------------> ${JSON.stringify(access_token)}`);
            //alert('Token resp CHECKING->'+JSON.stringify(access_token));
            if(access_token){
                dispatch({
                    type:SAVE_AUTH_TOKEN,
                    access_token: access_token
                })
            }
            if (access_token) {
                // Store the access token in a cookie
                setAccessToken(access_token);
              
                // Retrieve the access token from the cookie
                const accesstkn = getAccessToken();
                
                // Display the retrieved access token
                //alert("Acceskjfdtyis Token: " + accesstkn);
              }
              
            // alert(access_token);
        }
        catch(e){
            // alert('TOKEN----->'+JSON.stringify(access_token));
            //console.log("error in token ",e, "  : " + e.message)
        }
       
             
    }
    
    
    
}


export const callEncryptionAPI = (consentGroupId, clientId, txnId, mob, consentCreateTimestamp, timestamp, accessToken) => {
    return async (dispatch, getState) => {
        let headers = {
            'Content-Type': 'application/json',
            //Authorization: `Basic ${accessToken}`,
            clientId: clientId,
            txnId: txnId
        };

        let requestBody = {
            clientId: clientId,
            consentGroupId: consentGroupId,
            txnId: txnId,
            customerId: mob,
            consentCreateTimestamp: consentCreateTimestamp,
            timestamp: timestamp,
        };

        try {
            let response = await axios.post(config.ENCRYPTAPI, requestBody, { headers });
            let encryptedData = response.data.encryptedData;

            dispatch({
                type: ENCRYPTAPISUCCCESS,
                clientId:clientId,
                encryptedData: encryptedData,
            });

            return encryptedData; // Return the encrypted data
        } catch (e) {
            console.error("Error in callEncryptionAPI: ", e);
        }
    }
}


export const callRedirectAPI = (encryptedData, clientIDrecieved, callbackUrl) => {
    return async (dispatch, getState) => {
        //alert("hjojbj")
      const accessToken = getAccessToken(); // Get the token from cookies
      //const clientId = getState().common.clientId; // Access clientId from the Redux state
      //alert("clientId in callRedirectAPI->" +clientIdcallRedirectAPI);
  
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`, // Uncomment if authorization is required
        clientId:clientIDrecieved,
        callback: callbackUrl, // Replace with actual callback URL
        SubscriptionKey: '41421489a325462ba7d301e613603281'
      };
  
      try {
        // Call the GET API with encryptedData appended to the URL
        const response = await axios.get(
          `${encryptedData_Redirect_Portal}/getConsentPopupData?encryptedData=${encryptedData}`,
          { headers }
        );
  
        const datafromDecryptApi = response.data; // Extract data from the response
        console.log("datafromDecryptApi->" +JSON.stringify(datafromDecryptApi));
         // Extract the "data" field
        const { data } = datafromDecryptApi.consentNotice || {};
        //console.log("notice->" + JSON.stringify(data));
        const { consentGroupName, templates, logo, consentText, referenceId, appName, theme, identity, isMobile } = datafromDecryptApi;
        //alert("templates in common action->" + JSON.stringify(templates));
        // Dispatch the response data to the Redux store
        dispatch({
          type: DECRYPTAPISUCCESS,
          datafromDecryptApi, // Save the entire response data
          consentGroupName,
          templates,
          logo,
          consentText,
          referenceId,
          appName,
          theme,
          data,
          identity,
          isMobile
        });

       
  
        // Show the popup or take further action with the response
        //alert("API Call Successful. Data: " + JSON.stringify(datafromDecryptApi));
      } catch (error) {
        console.error("Error in callRedirectAPI: ", error);
        // window.location.href = headers.callback;
      }
    };
  };
  
 




export const createConsentAPI = ()=>{
    return async (dispatch,getState)=>{
 
        // console.log(`Generating authorization`,authorization)
        let headers = {
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${accessToken}`,
            clientId : clientId, // Use the token from cookies
        };
        let   createConsentBody = {
            referenceId: referenceId,
            templateId: ["7311f867-00a9-4a0a-a67e-1e5fba20c982"]
          };
 
        try{
            let  createConsentResponse = await makeAPICall(config.CREATCONSENT,"POST", createConsentBody, headers)
            //console.log(`token resp-----------------> ${JSON.stringify(resp)}`);
            let clientId = createConsentResponse.data.clientId;
            //console.log(`token resp CHECKING-----------------> ${JSON.stringify(access_token)}`);
            //alert('Token resp CHECKING->'+JSON.stringify(access_token));
            dispatch({
                type: CREATCONSENTSUCCESS,
                consentId:consentId,
                callback:callback,
                timestamp:timestamp,
                txnid:txnid
            })
        }
        catch(e){
            // alert('TOKEN----->'+JSON.stringify(access_token));
            //console.log("error in token ",e, "  : " + e.message)
        }
       
             
    }
}


 
export const GetTempDetail = (tempId,customerId,clientId) =>{
    return async (dispatch,getState)=>{
        let access_token = getState().common.access_token;
 
       // alert("tempId : "+tempId);
 
        const tempIdArray = tempId.split(",");
       // console.log(tempIdArray);
 
        var headers = {
            // "clientId":clientId,
            // "Authorization":`Bearer ${access_token}`
        }
        var request = {
            "consenttemplates":tempIdArray
            }
        try {
            let resp = await makeAPICall(config.GetMultipleTemplatesByTemplateIds, "POST", request, headers);
           // console.log('value of GetTempDetail resp ' +  JSON.stringify(resp))
            let data = resp.data
            // let clientId = data.clientId
            // console.log('resp of template detail ' + JSON.stringify(data))
           // console.log("templateList[0].templateDetails.dataConsumer:"+data.templateList[0].templateDetails.dataConsumer);
 
            dispatch({
                type:GET_TEMP_DETAILS,
                data,
                tempId,
                clientId,
                customerId
            })
        } catch (error) {
           // console.log('value of error in init '+JSON.stringify(error))
        }
       
    }
}
 
 
 
export const getTemplateDetailByConsentGroupId = (consentGroupId) =>{
 
    return async (dispatch,getState)=>{
 
       //alert("in getTemplateDetailByConsentGroupId "+consentGroupId);
        let access_token = getState().common.access_token;
 
        var headers = {
            // "clientId":clientId,
            // "Authorization":`Bearer ${access_token}`
            //"Ocp-Apim-Subscription-Key":"67f3ffc1caa7452580cacad971ff3bf7"
        }
        try {
            // let resp = await makeAPICall(config.GET_CONSENTGROUPID+`/${consentGroupId}`, "GET", {}, headers);
            // console.log('Response in getconsentGroupDataByGroupId ACTION 1 '+JSON.stringify(resp))
            // //alert('value of getPreferenceCenterThemeByClient resp ' +  JSON.stringify(resp))
            // let data = resp.data
 
           
                let respTemp = await makeAPICall(config.GET_TEMPLATE_BY_CONSENT_GROUPID+`/${consentGroupId}`, "GET", {}, headers);
                //console.log('Response in getconsentGroupDataByGroupId ACTION IF 2 '+JSON.stringify(respTemp.data))
                //alert('value of getPreferenceCenterThemeByClient resp ' +  JSON.stringify(resp))
                let templates = respTemp.data
                //data.templateIds=templates  
           
 
            //alert('value of getConsentGroupDataByGroupId in commonaction '+JSON.stringify(data))
           // alert(`Response getConsentGroupDataByGroupId---------------------> ${JSON.stringify(data)}`);
           // alert('data ' +  JSON.stringify(data))
 
            dispatch({
                type:GET_CONSENTGROUPDATABY_GROUPID,
                templates
            })
        } catch (error) {
           // console.log('value of error getConsentGroupDataByGroupId in commonaction '+JSON.stringify(error))
        }
       
    }
 
}
 
 
 
 
// export const getPreferenceCenterThemeByClient = (clientId) =>{
 
//     return async (dispatch,getState)=>{
 
//        // alert("in getPreferenceCenterThemeByClient");
//         let access_token = getState().common.access_token;
 
//         var headers = {
//             // "clientId":clientId,
//             // "Authorization":`Bearer ${access_token}`
//         }
//         try {
//             let resp = await makeAPICall(config.getPreferenceCenterThemeByClient, "GET", {}, headers);
//             //alert('value of getPreferenceCenterThemeByClient resp ' +  JSON.stringify(resp))
//             let data = resp.data
 
//            // alert('data ' +  JSON.stringify(data))
 
//             dispatch({
//                 type:GET_PREFERENCE_CENTER_THEME,
//                 data
//             })
//         } catch (error) {
//             console.log('value of error in init '+JSON.stringify(error))
//         }
       
//     }
 
// }
 
export const getPreferenceCenterThemeByGroupId = (consentGroupId) =>{
 
    return async (dispatch,getState)=>{
 
       // alert("in getPreferenceCenterThemeByClient");
        let access_token = getState().common.access_token;
 
        var headers = {
            // "clientId":clientId,
            // "Authorization":`Bearer ${access_token}`
        }
        try {
            let resp = await makeAPICall(config.getPreferenceCenterThemeByGroupId+`/${consentGroupId}`, "GET", {}, headers);
            //alert('value of getPreferenceCenterThemeByClient resp ' +  JSON.stringify(resp))
            let data = resp.data
 
           // alert('data ' +  JSON.stringify(data))
 
            dispatch({
                type:GET_PREFERENCE_CENTER_THEME,
                data
            })
        } catch (error) {
           // console.log('value of error in init '+JSON.stringify(error))
        }
       
    }
 
}
 
export const getConsentTextByGroupID = (consentGroupId) =>{
 
    return async (dispatch,getState)=>{
 
       // alert("in getPreferenceCenterThemeByClient");
        let access_token = getState().common.access_token;
 
        var headers = {
            // "clientId":clientId,
            // "Authorization":`Bearer ${access_token}`
        }
        try {
            let resp = await makeAPICall(config.GET_CONSENTGROUPID+`/${consentGroupId}`, "GET", {}, headers);
            //alert('value of getPreferenceCenterThemeByClient resp ' +  JSON.stringify(resp))
            let data = resp.data.consentText
 
           //alert('data ' +  JSON.stringify(data))
 
            dispatch({
                type:GET_CONSENT_TEXT,
                data
            })
        } catch (error) {
           // console.log('value of error in init '+JSON.stringify(error))
        }
       
    }
 
}
 
// export const CreateConsent = (templateIdArray, referenceId, clientIDrecieved)=>{
//     //alert("clientIDrecieved->" + clientIDrecieved);
//     //alert('INSIDE Create Consent-->')
//     return async (dispatch,getState)=>{
//         let customerId = getState().common.customerId;
//         //let templateId = getState().common.templateId;
//         let clientId = getState().common.clientId;
//         let clientSecret = getState().common.clientSecret;
//         // let tempIdArray = [];
       
 
//      //   alert("consent check in CreateConsent "+consentCheck);
 
//         let access_token = getState().common.access_token;
//         //alert("Access Token in CreateConsent "+access_token);
//         // if(consentCheck){
//         //     //alert("in true")
//         //     tempIdArray = templateId.split(",");
//         //     //alert("create consent tempIdArray : "+tempIdArray);
//         // }else{
//         //     //alert("in false")
//         //     tempIdArray = templateId.split(",")[0];
//         //    // alert("create consent tempIdArray : "+tempIdArray);
//         // }
       
//         //alert("create consent tempIdArray : "+tempIdArray);
 
//         var headers = {
//             clientId: clientIDrecieved,
//             // "Authorization":`Bearer ${access_token}`,
//             // "clientId" : clientIDrecieved,
//             // "clientSecret":clientSecret,
//         }
//         // var req = {
//         //     "referenceId": referenceId,
//         //     "consentDetail": {
//         //         "consentStart": start,
//         //         "customer": {
//         //             "referenceId": referenceId,
//         //             "consentTemplateID": templateIdArray
//         //         }
//         //     }
//         // }

//         var req = {
//             "referenceId": referenceId,
//             "templateIds": templateIdArray
//         };
//    // console.log('value of header in create ' +  JSON.stringify(headers) )
 
       
//         try {
//             //alert("headers->" + JSON.stringify(headers));
//             let resp = await APICall(config.CreateConsent, "POST", req, headers);
//             // console.log('value of CreateConsent resp ' +  JSON.stringify(resp))
//             let responseFromCreateConsent = resp.data
//             //alert("response-->" + responseFromCreateConsent.callback);
//            // console.log('resp of CreateConsent ' + JSON.stringify(resp))
//             dispatch({
//                 type:CALL_BACK_URL,
//                 responseFromCreateConsent
//             })
//         } catch (error) {
//             //console.log('value of error in init '+JSON.stringify(error))
//         }
       
//     }
// }

export const CreateConsent = (templateIdArray, referenceId, plainIdentity, mobileNumberFinal, mobileFlag, clientIDrecieved) => {
    return async (dispatch, getState) => {
      let customerId = getState().common.customerId;
      let clientId = getState().common.clientId;
      let clientSecret = getState().common.clientSecret;
      let access_token = getState().common.access_token;
  
      var headers = {
        clientId: clientIDrecieved,
        SubscriptionKey: '41421489a325462ba7d301e613603281'
      };
  
      var req = {
        referenceId: referenceId,
        identity:plainIdentity,
        mobile:mobileNumberFinal,
        isMobile:mobileFlag,
        templateIds: templateIdArray,
      };
  
      try {
        let resp = await APICall(config.CreateConsent, "POST", req, headers);
  
        // If the response indicates success, proceed
        if (resp.status === 200) {
          let responseFromCreateConsent = resp.data;
          dispatch({
            type: CALL_BACK_URL,
            responseFromCreateConsent,
          });
        } else {
          // If the response is not successful, throw an error
          throw new Error(`Failed to create consent: ${resp.statusText}`);
        }
      } catch (error) {
        console.error('Error during CreateConsent:', error);
        throw error; // Re-throw the error to be caught in the calling function
      }
    };
  };
  
 
export const consentByCustomerId = ()=>{
    //alert('Inside API call ')
    return async (dispatch,getState)=>{
 
       
 
        let ActiveList = []
        let RevokeList = []
        let AllConsent = []
 
        let customerId = getState().common.customerId;
         let clientId = getState().common.clientId;
         let clientSecret = getState().common.clientSecret;
 
         let access_token = getState().common.access_token;
 
         var headers = {
             "Content-Type":"application/json",
             "clientId" : clientId,
             "clientSecret":clientSecret,
            "txnId": "92929bc1-a28f-5d68-91af-44",
             "Authorization":`Bearer ${access_token}`,
             "SubscriptionKey": process.env.REACT_APP_SUBSCRIPTION_KEY,
        };
           
        let resp = await makeAPICall(config.GetConsentDetailsByCustomerId+`/${customerId}`, "GET", null, headers);
       // console.log('value of consentByCustomerId resp ' + JSON.stringify(resp))
        let list = resp.data
       // console.log(" -------------------------- active consent response ----------------- "+JSON.stringify(list));
        list.map((item,key) => {
            //   console.log(`value of signedConsent ${item.signedConsent}`)
          // console.log("item : "+item);
            if (item.status == 'ACTIVE' )
                {
                ActiveList.push(item);
                AllConsent.unshift(item)
                // ActiveList.unshift(item)
                }          
          })
 
         
 
          let respRevoked = await makeAPICall(config.GetRevokedConsentDetailConsentId+`/${customerId}`, "GET", null, headers);
          //alert('value of consentByCustomerId respRevoked ' + JSON.stringify(respRevoked))
          let revokedList = respRevoked.data
 
        revokedList.map((item,key) => {
                    RevokeList.push(item);
                    AllConsent.unshift(item)      
          })
         
        //   console.log('value of consentByCID resp ' + (RevokeList))
 
        dispatch({
            type:CONSENT_HISTORY,
            ActiveList,
            RevokeList,
            AllConsent
        })
    }
}
 
 
export const consentBytxnId = ()=>{
    return async (dispatch,getState)=>{
 
       
 
        let ActiveList = []
        let RevokeList = []
        let AllConsent = []
 
        let customerId = getState().common.customerId;
         let clientId = getState().common.clientId;
         let clientSecret = getState().common.clientSecret;
 
         let access_token = getState().common.access_token;
 
         var headers = {
             "Content-Type":"application/json",
             "clientId" : clientId,
             "clientSecret":clientSecret,
            "txnId": "92929bc1-a28f-5d68-91af-44",
             "Authorization":`Bearer ${access_token}`,
             "SubscriptionKey": process.env.REACT_APP_SUBSCRIPTION_KEY,
        };
           
        let resp = await makeAPICall(config.GetConsentDetailsByTxnId+`/${txnId}`, "GET", null, headers);
        //console.log('value of consentByCustomerId resp ' + JSON.stringify(resp))
        let list = resp.data
        //console.log(" -------------------------- active consent response ----------------- "+JSON.stringify(list));
        list.map((item,key) => {
            //   console.log(`value of signedConsent ${item.signedConsent}`)
          // console.log("item : "+item);
            if (item.status == 'ACTIVE' )
                {
                ActiveList.push(item);
                AllConsent.unshift(item)
                // ActiveList.unshift(item)
                }          
          })
 
         
 
          let respRevoked = await makeAPICall(config.GetConsentDetailsByTxnId+`/${txnId}`, "GET", null, headers);
          //alert('value of consentByCustomerId respRevoked ' + JSON.stringify(respRevoked))
          let revokedList = respRevoked.data
 
        revokedList.map((item,key) => {
            if (item.status == 'INACTIVE' )
                {
                    RevokeList.push(item);
                    AllConsent.unshift(item)
                }      
          })
         
        //   console.log('value of consentByCID resp ' + (RevokeList))
 
        dispatch({
            type:CONSENT_HISTORY,
            ActiveList,
            RevokeList,
            AllConsent
        })
    }
}
 
 
export const getProfileData = (clientId) => {
 
    //alert("client Id is called "+clientId);
 
    return async (dispatch, getState) => {
        const access_token = getState().common.access_token;
        //  let clientId = getState().common.clientId;
        //  let clientSecret = getState().common.clientSecret;
        //  let access_token = getState().common.access_token;
         
        // console.log ("Access Token",access_token );
         let headers = {
            // "clientId" : clientId,
            // "clientSecret":clientSecret,
            // "Authorization":`Bearer ${access_token}`
           
             
         }
 
         let resp = await makeAPICall(config.GET_PROFILE_DATA+`/${clientId}`,"GET", {}, headers)
         //console.log(`Response getProfileData ${JSON.stringify(resp)}`);
         let profileData = resp.data
 
        // console.log(`Response profileData ${JSON.stringify(profileData)}`);
        const App_Name = profileData.appName;
        const App_Logo = profileData.logo;
 
         // let logoBase64 = resp.data.logo;
         // console.log("logoBase64 :"+logoBase64);
 
         // base64ToImage(logoBase64)
         //     .then((image) => {
         //         console.log(image);
         //     })
         //     .catch((error) => {
         //         // An error occurred while loading the image
         //         console.error(error);
         //     });
 
         dispatch({
             type: PROFILE_DATA,
             profileData
         })
         dispatch({
            type: APP_NAME,
            App_Name
        })
        dispatch({
            type: APP_LOGO,
            App_Logo
        })
     
       
     }
     
     
 }
 
 
 
export const revokeConsentCall = (item)=>{
    return async (dispatch,getState)=>{
       
       // alert("item.consentId : "+JSON.stringify(item));
        let clientId = config.CLIENT_ID;
        let clientSecret = config.CLIENT_SECRET_VALUE
 
       let access_token = getState().common.access_token;
 
       //alert("access_token : "+access_token);
 
        var headers = {
            "Content-Type":"application/json",
            "clientId" : clientId,
            "clientSecret":clientSecret,
            "txnId": "92929bc1-a28f-5d68-91af-44",
            "Authorization":`Bearer ${access_token}`,
            "SubscriptionKey": process.env.REACT_APP_SUBSCRIPTION_KEY,
        };
 
       // console.log("headers for this request : "+JSON.stringify(headers)+" for url :"+config.REVOKE_CONSENT+"/"+item.consentId);
 
        let resp = await makeAPICall(`${config.REVOKE_CONSENT}/${item}`, "PUT", null, headers)
       //alert(`Response revokeConsent ${JSON.stringify(resp)}`);
       // alert(JSON.stringify(resp));
 
       
       
       // console.log('Consent with Consent Id:'+item.consentId+' Revoked Successfully');
 
        //alert("returning resp.data.status : "+resp.data.status);
 
        return resp.status;
        // dispatch({
        //      type: CONSENT_REVOKE_SUCCESS
        //  })
    // }catch(e) {
    //     console.log("ERROR ", e , JSON.stringify(e));
    //     throw new Error(e);
 
    // }
    }
}
 
 
 
export const saveTempId = (consentGroupId,mob,clientId,clientSecret) => {
    //alert('save consentGroupId'+ consentGroupId)
    return async (dispatch,getState)=>{
       // console.log("val in action to save data", consentGroupId , mob , clientId,clientSecret )
 
        dispatch({
            type:SAVE_TEMPID,
            consentGroupId,
            mob,
            clientId,
            clientSecret
        })
    }
}
 
 
export const saveParamsToState = (customerId, clientId, clientSecret, consentGroupId, callbackURL)=>{
    return async (dispatch,getState)=>{
        dispatch({
            type:paramsFromState,
            customerId,
            clientId,
            clientSecret,
            consentGroupId,
            callbackURL
        })
    }
}
 
export const saveParamsToStateHistory = (customerId, clientId, clientSecret)=>{
    return async (dispatch,getState)=>{
        dispatch({
            type:paramsFromStateHistory,
            customerId,
            clientId,
            clientSecret,
        })
    }
}